import React, {useEffect, useState} from 'react';
import { ConsultaAtuF } from "../../../state/actions/Dashboard/AtuActions"
import { useSelector, useDispatch } from "react-redux"
import Table from "react-bootstrap/Table"

const AtuDetalleComponent = () => {

    const ATU = useSelector(state => state.Atu.Atu)
    const dispatch = useDispatch()

    const [Key, setKey] = useState("")

    useEffect(() => {
    
        if (ATU.length === 0) {
            const ConsultaAtu = () => dispatch(ConsultaAtuF())
            ConsultaAtu()
        }

        setKey(localStorage.getItem('atukey'))
      
        // eslint-disable-next-line react-hooks/exhaustive-deps
    
      }, [])

    return ( 
        
        <>
            <Table striped bordered hover size="sm">
                    {ATU.filter(func => String(func.key) === String(Key)).map(
                    (row, i) => (
                        <tbody key={i}>
                        <tr>
                            <th scope="row">Tipo Presentación:</th>
                            <td className="text-justify">{row.tipo_presentacion}</td>
                        </tr>
                        <tr>
                            <th scope="row">Canal:</th>
                            <td className="text-justify">{row.canal}</td>
                        </tr>
                        <tr>
                            <th scope="row">Fecha Ingreso:</th>
                            <td className="text-justify">{row.fecha_ingreso}</td>
                        </tr>
                        <tr>
                            <th scope="row">Atención Entregada:</th>
                            <td className="text-justify">{row.atencion_entregada}</td>
                        </tr>
                        <tr>
                            <th scope="row">Estado:</th>
                            <td className="text-justify">{row.estado_reclamo}</td>
                        </tr>
                        
                        <tr>
                            <th scope="row">Antecedente:</th>
                            <td className="text-justify">{row.antecedente}</td>
                        </tr>
                        
                        <tr>
                            <th scope="row">Petición:</th>
                            <td className="text-justify">{row.peticion}</td>
                        </tr>
                        
                        {row.fecha_respuesta && row.respuesta && 
                        <>
                            <tr>
                                <th scope="row">Fecha Repuesta:</th>
                                <td className="text-justify">{row.fecha_respuesta}</td>
                            </tr>
                            <tr>
                                <th scope="row">Repuesta:</th>
                                <td className="text-justify">{row.respuesta}</td>
                            </tr>
                        </>
                        }
                        </tbody>))}
              </Table>
        </>
     );
}
 
export default AtuDetalleComponent;