import { GET_ATU, GET_ATU_STATUS } from "../../types"
import clienteAxios from "../../../Utils/axios"

export function ConsultaAtuF() {
  return async dispatch => {
    try {
      const tokenaccess = localStorage.getItem("token")
      const rutsession = localStorage.getItem("rut")
      const dvsession = localStorage.getItem("dv")
      const dataForm = new FormData()
      dataForm.append("run", rutsession)
      dataForm.append("dv", dvsession)

      const respuesta = await clienteAxios.post("ConsultaATU/", dataForm, {
        headers: {
          Authorization: "Bearer " + tokenaccess,
          accept: "application/json",
        },
      })

      dispatch(Get_Status_F(respuesta.status))
      if (respuesta.data !== "") {
        dispatch(Get_Atu_F(respuesta.data.atu))
      } else {
        dispatch(Get_Atu_F([]))
      }
    } catch (error) {
      dispatch(Get_Status_F(500))
    }
  }
}

const Get_Atu_F = datos => ({
  type: GET_ATU,
  payload: datos,
})

const Get_Status_F = datos => ({
  type: GET_ATU_STATUS,
  payload: datos,
})
